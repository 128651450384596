import { useAppKitAccount } from "@reown/appkit/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { WalletConnect } from "../WalletConnect/WalletConntect";
import background from './background.png';
import { Input } from "../functions/Input";
import styles from './Referral.module.css'; // Import CSS Module
import './Referral.css'; // Import CSS Module
import { initializeApp } from "firebase/app";
import { Header } from "./Header";
import { Footer } from "./Footer";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC-teETwqkUxOM27zgawA8AsnQC2yvcraI",
    authDomain: "starchain-app.firebaseapp.com",
    projectId: "starchain-app",
    storageBucket: "starchain-app.appspot.com",
    messagingSenderId: "173972643442",
    appId: "1:173972643442:web:fdd9ce39f0cd8670d02ceb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

interface IPurchase {
    timestamp: number;
    value: number;
    transactionId: string;
}

const comissionUSDT = 0.17
const comissionSTRC = 0.08

interface IReferralResponse {
    refLink: string;
    sales: IPurchase[];
}

const round = (number: number): string => {
    return (Math.round(number * 100) / 100).toFixed(2)
}

const RefLink = (link: string) => {
    return (
        <div className={styles.refLink}>
            <div>
                <Input overwriteStyle={{color: "lightgray"}} readonly value={link} />
            </div>
        </div>
    );
};

export const Referral = () => {
    const [referralData, setReferralData] = useState<IReferralResponse | null>(null);
    const functions = getFunctions();
    const callableReturnMessage = httpsCallable(functions, 'getReferralData');

    const { address, isConnected } = useAppKitAccount();
    let fetching = false;

    useEffect(() => {
        async function getReferralData() {
            fetching = true;
            try {
                const response = await callableReturnMessage({ walletAddress: address });
                console.error(response);
                setReferralData(response.data as IReferralResponse);
            } catch (e) {
                // Handle error
            }
        }

        if (!fetching && !referralData && isConnected) {
            getReferralData().then(() => (fetching = false));
        }
    }, [address]);

    const NoSalesCard = () => {
        return (
            <div className={styles.noSalesCard}>
                <div className={styles.noSalesText}>
                    Purchases Made Using Your Link Will Show Up Here
                </div>
            </div>
        );
    };

    const Blur = (className: string) => {
        return <div className={styles.bgBlur + " " + className}></div>
    }

    const WithdrawalCard = () => {
        return (
            <div className={styles.withdrawalCard}>
                <div className={styles.withdrawalText}>
                    Each sale will be payed out within 7 days
                </div>
            </div>
        );
    };

    const SaleCard = (purchase: IPurchase, index: number) => {
        return (
            <div className={styles.saleCard}>
                <div className={styles.salesCardUpper}>
                    <div className={styles.salesCardUpperLeft}>
                        <div className={styles.salesCardIndex}>
                            {index}.
                        </div>
                    <div className={styles.salesCardTimestamp}>
                        {new Date(purchase.timestamp).toLocaleString()}
                    </div>
                    </div>
                    <div className={styles.salesCardValue}>
                        ${round(purchase.value)}
                    </div>
                </div>
                {/* <div>{purchase.transactionId}</div> */}
                <div>
                     <div className={styles.salesCardRow} style={{color: "#a7a7a7", fontWeight: 400}}><div>USDT Earned</div><div>${round(purchase.value * comissionUSDT)}</div></div>
                    <div className={styles.salesCardRow} style={{color: "#a7a7a7", fontWeight: 400}}><div>STRC Earned (in $)</div><div>${round(purchase.value * comissionSTRC)}</div></div>
                    <div className={styles.salesCardRow} style={{fontWeight: 600}}><div>Total Earned</div><div className={styles.totalEarned}>${round(purchase.value * (comissionUSDT + comissionSTRC))}</div></div>
                </div>
            </div>
        );  
    };

    const SalesCards = (sales: IPurchase[]) => {
        return <div className={styles.salesCardContainer}>{sales.sort(s => s.timestamp).reverse().map((s, i) => SaleCard(s, i + 1))}{WithdrawalCard()}</div>;
    };

    const NumberCard = (upper: string, lower: string[], wide?: boolean) => {
        return (
            <div className={styles.numberCardBorder}>
                <div className={styles.numberCard + (wide ? " " + styles.numberCardWide : "")} style={{ textAlign: "center" }}>
                    <div className={styles.numberCardUpper + " " + (lower.length > 1 ? styles.numberCardUpperSmall : "")}>{upper}</div>
                    <div className={styles.numberCardLower + " " + (lower.length > 1 ? styles.numberCardLowerSmall : "")}>{lower.map(l => <div>{l}</div>)}</div>
                </div>
            </div>
        );
    };

    const EarnedCard = (upper: string, lower: string, special: boolean) => {
        return (
            <div className={styles.earnedCard}>
                <div className={styles.earnedCardTitle}>{upper}</div>
                <div className={(styles.earnedCardAmount + " " + (special ? styles.earnedCardAmountSpecial : ""))}>{lower}</div>
            </div>
        );
    };


    const data = (referralData: IReferralResponse | null) => {

        const totalUSDT = referralData?.sales.map(s => s.value * comissionUSDT).reduce((accumulator, currentValue) => {
                return accumulator + currentValue
            }, 0) ?? 0;

            const totalSTRC = referralData?.sales.map(s => s.value * comissionSTRC).reduce((accumulator, currentValue) => {
                    return accumulator + currentValue
                }, 0) ?? 0;

            const totalAll = totalSTRC + totalUSDT;
        return (
            <div className={styles.dataContainer}>
                
                <div className={styles.leftSection}>
                    <div className={styles.headline}>
                        Find Investors
                        <br />
                        and Earn 25%
                    </div>
                    <div className={styles.numberCardContainer}>
                        {NumberCard("17%", ["in USDT"])}
                        {NumberCard("8%", ["in STRC"])}
                        {NumberCard("Payout Schedule", ["USDT: Weekly", "STRC: At TGE"], true)}
                    </div>
                    {/* <div className={styles.missionStatement}>
                        Help StarChain attract new Investors and 
                    </div> */}
                    <div
                        className={styles.referralLinkSection}
                        style={{ backgroundImage: `url(${background})` }}
                    >
                        <div className={styles.referralLinkTitle}>Your Referral Link</div>
                        <div style={{width: "100%"}}>
                            {referralData ? RefLink(referralData.refLink) : <WalletConnect />}
                        </div>
                        <span style={{color: "lightgray", marginTop: "0.35rem"}}>Purchases made using a VPN or with Script-Blockers may not be tracked</span>
                    </div>
                </div>
                <div className={styles.rightSection}>
                    <div className={styles.referralsTitle}>My Referrals</div>
                    <div className={styles.earnedCardContainer}>
                        {EarnedCard("Total Earned ($)", "$"+round(totalAll), true)}
                        {EarnedCard("USDT Earned ($)", "$"+round(totalUSDT), false)}
                        {EarnedCard("STRC Earned ($)", "$"+round(totalSTRC), false)}
                    </div>
                    {referralData && referralData.sales.length > 0 ? (
                        SalesCards(referralData.sales)
                    ) : (
                        NoSalesCard()
                    )}
                </div>
            </div>
        );
    };

    return <div className={styles.referralContainer}><Header />{data(referralData)}{Blur(styles.blur1)}<Footer /></div>;
};
