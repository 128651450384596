import React, { ReactNode, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from "firebase/app";
import styles from "./DataStoreExplorer.module.css";
// TODO: Add SDKs for Firebase products that you want to use

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { DownloadPopup } from "../FTPDownloadPopup/DownloadPopup";
import { PopupProps } from "reactjs-popup/dist/types";
import { Button } from "../functions/Button";
import { SpecificationPopup } from "../UploadPopup/UploadPopup";

const DataStoreTitle = () => {
  return (
    <h1 className={styles.pageTitle}>
      Welcome to the <br />
      <span className={styles.gradient}>DataStore Explorer</span>
    </h1>
  );
};

export interface IDataset {
  // id: s
  recordedBy: string;
  reviewerCount: number;
  filesize: string;
  rating: number;
  downloadUrl?: string;
  ftpIp?: string;
  ftpHostName?: string;
  ftpUsername?: string;
  ftpPassword?: string;
  ftpPort?: string;
  categories: string[];
  specification: ISpecification;
  hidden?: boolean;
}

const DoubleField = (upperText: string, lowerText: string) => {
  return (
    <div className={styles.doubleField}>
      <p className={styles.upperText}>{upperText}</p>
      <p className={styles.lowerText}>{lowerText}</p>
    </div>
  );
};

const Dataset = (props: {
  dataset: IDataset;
  openModal: (dataset: IDataset) => void;
  openSpecification: () => void;
}) => {
  return (
    <div className={styles.dataset}>
      <div className={styles.datasetUpper}>
        <div className={styles.datasetImage}>
          <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/telescope%201.png?alt=media&token=7d247101-2f40-420b-9792-4a4f1aa28ed7" />
        </div>
        <div className={styles.datasetRight}>
          <div className={styles.datasetTitle}>
            {props.dataset.specification.name}
          </div>
          <div className={styles.datasetRecordedBy}>
            <p className={styles.recordedBy}>Recorded by</p>
            <p className={styles.university}>{props.dataset.recordedBy}</p>
          </div>
        </div>
      </div>
      <div className={styles.datasetCenter}>
        {DoubleField("Reviewers", props.dataset.reviewerCount.toString())}
        {DoubleField("Filesize", props.dataset.filesize)}
        {DoubleField("Rated", props.dataset.rating.toString())}
      </div>
      <div className={styles.datasetBottom}>
        <button
          className={styles.viewSpecification}
          onClick={() => props.openSpecification()}
        >
          View Specification
        </button>
        <div className={styles.download}>
          <Button
            trackClick={() => props.openModal(props.dataset)}
            name="Download"
          />
        </div>
      </div>
    </div>
  );
};

const host = "ftp://54.201.167.24";
const datasets: IDataset[] = [
  {
    recordedBy: "The University of Chicago",
    reviewerCount: 342,
    filesize: "6.2TB",
    rating: 4.29,
    // ftpIp: "ftp://154.56.59.123",
    ftpHostName: host,
    ftpUsername: "apo-spectro-public-reader",
    ftpPassword: "a5SwlV9CGhGnQyy",
    ftpPort: "21",
    categories: ["all", "astronomy"],
    specification: {
      shortDescription: ``,

      name: "SDSS DR17 APO SPECTRO",
    },
  },
  {
    recordedBy: "The University of Chicago",
    reviewerCount: 45,
    filesize: "1.5TB",
    rating: 4.18,
    // ftpIp: "ftp://154.56.59.123",
    ftpHostName: host,
    ftpUsername: "lco-bcam-public-reader",
    ftpPassword: "pyUQfuSzatf5dPp",
    ftpPort: "21",
    categories: ["all", "astronomy"],
    specification: {
      shortDescription: ``,

      name: "SDSS DR17 LCO BCAM",
    },
  },
  {
    recordedBy: "The University of Chicago",
    reviewerCount: 58,
    filesize: "1.8TB",
    rating: 4.67,
    // ftpIp: "ftp://154.56.59.123",
    ftpHostName: host,
    ftpUsername: "lco-gcam-public-reader",
    ftpPassword: "13ugbGjs4mt54CR",
    ftpPort: "21",
    categories: ["all", "astronomy"],
    specification: {
      shortDescription: ``,

      name: "SDSS DR17 LCO GCAM",
    },
  },
  {
    recordedBy: "The University of Chicago",
    reviewerCount: 19,
    filesize: "417M",
    rating: 4.34,
    // ftpIp: "ftp://154.56.59.123",
    ftpHostName: host,
    ftpUsername: "lco-mapper-public-reader",
    ftpPassword: "aFwiEmleKW7lgne",
    ftpPort: "21",
    categories: ["all", "astronomy"],
    specification: {
      shortDescription: ``,

      name: "SDSS DR17 LCO MAPPER",
    }
  },
  {
    recordedBy: "The University of Chicago",
    reviewerCount: 449,
    filesize: "417M",
    rating: 4.34,
    // ftpIp: "ftp://154.56.59.123",
    ftpHostName: host,
    ftpUsername: "lco-mapper-public-reader",
    ftpPassword: "aFwiEmleKW7lgne",
    ftpPort: "21",
    categories: ["all", "astronomy"],
    specification: {
      shortDescription: ``,

      name: "SDSS DR17 APOGEE SPECTRO ASPCAP",
    },
    hidden: true
  },
  {
    recordedBy: "The University of Chicago",
    reviewerCount: 511,
    filesize: "417M",
    rating: 4.34,
    // ftpIp: "ftp://154.56.59.123",
    ftpHostName: host,
    ftpUsername: "lco-mapper-public-reader",
    ftpPassword: "aFwiEmleKW7lgne",
    ftpPort: "21",
    categories: ["all", "astronomy"],
    specification: {
      shortDescription: ``,

      name: "SDSS DR17 MANGA SPECTRO ANALYSIS",
    },
    hidden: true
  },
  {
    recordedBy: "European Space Agency",
    reviewerCount: 478,
    filesize: "658GB",
    rating: 3.95,
    // ftpIp: "ftp://154.56.59.123",
    ftpHostName: host,
    ftpUsername: "gaia-edr3-source-public-reader",
    ftpPassword: "cQUinDieNesT",
    ftpPort: "21",
    categories: ["all", "astronomy"],
    specification: {
      shortDescription: "The Gaia Early Data Release 3 (EDR3) source dataset is a comprehensive astronomical catalog produced by the European Space Agency's Gaia mission. It contains detailed positional and velocity data for over 1.8 billion stars, celestial objects, and galaxies in the Milky Way and beyond. This dataset includes improved accuracy in astrometric measurements, such as star positions, proper motions, and parallax, enabling more precise studies of the structure, dynamics, and evolution of our galaxy. EDR3 also offers refined photometric data, aiding in the classification and analysis of various astrophysical objects.",

      name: "Gaia EDR3 Source CSV",
    },
  },
  {
    recordedBy: "European Space Agency",
    reviewerCount: 237,
    filesize: "658GB",
    rating: 3.95,
    // ftpIp: "ftp://154.56.59.123",
    ftpHostName: host,
    ftpUsername: "gaia-dr2-source-public-reader",
    ftpPassword: "cQUinDieNesT",
    categories: ["all", "astronomy"],
    specification: {
      shortDescription: ``,
      //
      name: "Gaia DR2 Source CSV",
    },
    hidden: true,
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "NIH Chest X-rays",
    },
    recordedBy: "National Institute of Health",
    reviewerCount: 17,
    filesize: "45GB",
    rating: 4.54,
    ftpUsername: "NIH-chest-xray-public-reader",
    ftpPassword: "UTHmaNDpitpA",
    categories: ["all", "medicine"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "DiffusionDB-2M –– Part 0001 to 0100",
    },
    recordedBy: "DiffusionDB",
    reviewerCount: 36,
    filesize: "61GB",
    rating: 4.73,
    ftpUsername: "diffusion-db-1-public-reader",
    ftpPassword: "NeTErWORAnTR",
    categories: ["all", "machine-learning"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "DiffusionDB-2M –– Part 0101 to 0200",
    },
    recordedBy: "DiffusionDB",
    reviewerCount: 36,
    filesize: "61GB",
    rating: 4.33,
    ftpUsername: "diffusion-db-2-public-reader",
    ftpPassword: "oRdNeXPlatrI",
    categories: ["all", "machine-learning"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "DiffusionDB-2M –– Part 0201 to 0300",
    },

    recordedBy: "DiffusionDB",
    reviewerCount: 28,
    filesize: "66GB",
    rating: 4.69,
    ftpUsername: "diffusion-db-3-public-reader",
    ftpPassword: "mcADvENDAnGu",
    categories: ["all", "machine-learning"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Disease Prediction Using Machine Learning",
    },

    recordedBy: "IEEE",
    reviewerCount: 41,
    filesize: "30KB",
    rating: 4.94,
    ftpUsername: "dpml-public-reader",
    ftpPassword: "NkPOringelFu",
    categories: ["all", "machine-learning"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Ocular Disease Recognition",
    },

    recordedBy: "Odir",
    reviewerCount: 6,
    filesize: "2GB",
    rating: 5.0,
    ftpUsername: "ocular-disease-recognition-public-reader",
    ftpPassword: "rABDoMYOuThE",
    categories: ["all", "medicine"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Machine Predictive Maintenance Classification",
    },

    recordedBy: "UCI ML Group",
    reviewerCount: 25,
    filesize: "137KB",
    rating: 3.88,
    ftpUsername: "mpmc-public-reader",
    ftpPassword: "WToNaltuCtRi",
    categories: ["all", "machine-learning"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Brain Tumor Segmentation 2021 Task 1",
    },

    recordedBy: "Synapse Org",
    reviewerCount: 1,
    filesize: "13GB",
    rating: 3.72,
    ftpUsername: "brats2021-public-reader",
    ftpPassword: "bRzdoxhoHAsr",
    categories: ["all", "medicine"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Brain Tumor Segmentation 2020 Training + Validation",
    },

    recordedBy: "RSNA-ASNR-MICCAI",
    reviewerCount: 11,
    filesize: "4.5GB",
    rating: 4.47,
    ftpUsername: "brats2020-public-reader",
    ftpPassword: "SOnWxapWqyCH",
    categories: ["all", "medicine"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Dermatology Skin Diseases",
    },

    recordedBy: "Dermnetnz",
    reviewerCount: 16,
    filesize: "2GB",
    rating: 4.88,
    ftpUsername: "dermnet-public-reader",
    ftpPassword: "cCuflDhXZsQi",
    categories: ["all", "medicine"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Histopathologic Oral Cancer Detection",
    },

    recordedBy: "N/A",
    reviewerCount: 4,
    filesize: "3GB",
    rating: 4.9,
    ftpUsername: "hocd-public-reader",
    ftpPassword: "FJVHZGUgkagN",
    categories: ["all", "medicine"],
    ftpPort: "21",
    hidden: true
  },

  {
    specification: {
      shortDescription: ``,
      name: "Melanoma Detection Dataset",
    },

    recordedBy: "Arvix Org 1710.05006",
    reviewerCount: 37,
    filesize: "12GB",
    rating: 4.15,
    ftpUsername: "mdd-public-reader",
    ftpPassword: "LiixprfWjSXC",
    categories: ["all", "medicine"],
    ftpPort: "21",
    hidden: true,
  },

  {
    specification: {
      shortDescription: ``,
      name: "Edge-IIoTset Cyber Security Dataset of IoT & IIoT",
    },

    recordedBy: "CC BY-NC-SA 4.0",
    reviewerCount: 3,
    filesize: "2GB",
    rating: 4.48,
    ftpUsername: "Edge-IIoT-public-reader",
    ftpPassword: "NQPkxmNospZJ",
    categories: ["all", "cyber-security"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Car Crash or Collision Prediction Dataset",
    },

    recordedBy: "Vis bdd100k",
    reviewerCount: 35,
    filesize: "1GB",
    rating: 4.86,
    ftpUsername: "CCoCPD-public-reader",
    ftpPassword: "OGNkkeDaqZaG",
    categories: ["all", "transportation"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Stanford Covid Vaccine Onodera Model",
    },

    recordedBy: "Standford University",
    reviewerCount: 33,
    filesize: "15GB",
    rating: 4.38,
    ftpUsername: "CVOM-public-reader",
    ftpPassword: "odBNXZoqBMKZ",
    categories: ["all", "medicine"],
    ftpPort: "21",
    hidden: true
  },

  {
    specification: {
      shortDescription: ``,
      name: "Pfizer BioNTech Vaccine Dataset",
    },

    recordedBy: "Pfizer",
    reviewerCount: 17,
    filesize: "1.5GB",
    rating: 4.84,
    ftpUsername: "PNTech-public-reader",
    ftpPassword: "wCPoMCFDxKZN",
    categories: ["all", "medicine"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Kitsune Network Attack Dataset",
    },

    recordedBy: "NDSS'18",
    reviewerCount: 9,
    filesize: "19GB",
    rating: 4.46,
    ftpUsername: "KNAD-public-reader",
    ftpPassword: "CnelaCZMjhAz",
    categories: ["all", "cyber-security"],
    ftpPort: "21",
    hidden: true
  },

  {
    specification: {
      shortDescription: ``,
      name: "New Plant Diseases Dataset",
    },

    recordedBy: "PlantVillage repo",
    reviewerCount: 26,
    filesize: "3GB",
    rating: 4.52,
    ftpUsername: "npdd-public-reader",
    ftpPassword: "iNeZtHAgOZHE",
    categories: ["all", "biology"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Plant Leaf Disease Detection Image Dataset",
    },

    recordedBy: "PlantifyDB",
    reviewerCount: 14,
    filesize: "3GB",
    rating: 4.25,
    ftpUsername: "pldd-public-reader",
    ftpPassword: "WkuVAEqJyzmj",
    categories: ["all", "biology"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Diabetic Retinopathy",
    },

    recordedBy: "American Academy of Ophthalmology",
    reviewerCount: 10,
    filesize: "8GB",
    rating: 3.73,
    ftpUsername: "DiabeticRP-public-reader",
    ftpPassword: "nHHFFuIrquRe",
    categories: ["all", "medicine"],
    ftpPort: "21",
    hidden: true
  },

  {
    specification: {
      shortDescription: ``,
      name: "Plant Classification",
    },

    recordedBy: "Modelscope",
    reviewerCount: 24,
    filesize: "1.5GB",
    rating: 3.75,
    ftpUsername: "PIC-public-reader",
    ftpPassword: "DqhWeNGpvKMr",
    categories: ["all", "biology"],
    ftpPort: "21",
    hidden: true
  },

  {
    specification: {
      shortDescription: ``,
      name: "Animal Species Classification - V3",
    },

    recordedBy: "WIE repo",
    reviewerCount: 44,
    filesize: "20GB",
    rating: 4.44,
    ftpUsername: "ASCv3-public-reader",
    ftpPassword: "yRRGLtoHvUNZ",
    categories: ["all", "biology"],
    ftpPort: "21",
    hidden: true
  },

  {
    specification: {
      shortDescription: ``,
      name: "Age Assessment & Disease Risk Prediction",
    },

    recordedBy: "N/A",
    reviewerCount: 9,
    filesize: "10GB",
    rating: 4.65,
    ftpUsername: "AADRP-public-reader",
    ftpPassword: "WcchlSPGpwqg",
    categories: ["all", "medicine"],
    ftpPort: "21",
    hidden: true,
  },

  {
    specification: {
      shortDescription: ``,
      name: "Car Crash Dataset",
    },

    recordedBy: "MCCC",
    reviewerCount: 41,
    filesize: "4GB",
    rating: 4.97,
    ftpUsername: "CCD-public-reader",
    ftpPassword: "tNrbRLGBxZVS",
    categories: ["all", "transportation"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Car Dekho Dataset",
    },

    recordedBy: "SDW",
    reviewerCount: 19,
    filesize: "300MB",
    rating: 3.71,
    ftpUsername: "VDDekho-public-reader",
    ftpPassword: "fAzBrxcZHaby",
    categories: ["all", "transportation"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Co2 Emission by Vehicles",
    },

    recordedBy: "Canada Open Data for Climate",
    reviewerCount: 5,
    filesize: "90kB",
    rating: 3.91,
    ftpUsername: "COEBV-public-reader",
    ftpPassword: "jfZQreVhHcFx",
    categories: ["all", "climate"],
    ftpPort: "21",
  },
  {
    specification: {
      shortDescription: ``,
      name: "Earth Surface Temperature Data",
    },

    recordedBy: "NASA GISTEMP / NOAA's MLOST",
    reviewerCount: 14,
    filesize: "87GB",
    rating: 4.74,
    ftpUsername: "CCESTD-public-reader",
    ftpPassword: "kXFpLRBuelzG",
    categories: ["all", "climate"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Climate Environmental Rasters",
    },

    recordedBy: "Plantnet",
    reviewerCount: 15,
    filesize: "23GB",
    rating: 3.74,
    ftpUsername: "CCLF-public-reader",
    ftpPassword: "VzPJBdiGvWfv",
    categories: ["all", "climate"],
    ftpPort: "21",
    hidden: true
  },

  {
    specification: {
      shortDescription: ``,
      name: "Nasa Main Surface Forces / Climate Change",
    },

    recordedBy: "NASA",
    reviewerCount: 6,
    filesize: "1.5GB",
    rating: 3.98,
    ftpUsername: "MSF-Climate-public-reader",
    ftpPassword: "kAUpntECKYKs",
    categories: ["all", "climate"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Forest Fire - Detection Algorithm",
    },

    recordedBy: "USTC Edu",
    reviewerCount: 37,
    filesize: "3GB",
    rating: 4.19,
    ftpUsername: "FFDA-public-reader",
    ftpPassword: "SzjsWmbGnObq",
    categories: ["all", "climate"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Seismic Waves Detection",
    },

    recordedBy: "NASA",
    reviewerCount: 26,
    filesize: "2.5GB",
    rating: 4.1,
    ftpUsername: "SMIC-WS-public-reader",
    ftpPassword: "GgfhmKMdIjVE",
    categories: ["all", "astronomy"],
    ftpPort: "21",
  },

  {
    specification: {
      shortDescription: ``,
      name: "Gravity Spy (Gravitational Waves)",
    },

    recordedBy: "LIGO",
    reviewerCount: 18,
    filesize: "4GB",
    rating: 4.26,
    ftpUsername: "GVTWVS-public-reader",
    ftpPassword: "pESmacQiPaTE",
    categories: ["all", "astronomy"],
    ftpPort: "21",
    hidden: true
  },

  {
    specification: {
      shortDescription: ``,
      name: "SETI Simulated Signal Data",
    },
    recordedBy: "SETI",
    reviewerCount: 26,
    filesize: "5.5GB",
    rating: 4.91,
    ftpUsername: "SETI-1-public-reader",
    ftpPassword: "wQNmtHfYeNYR",
    categories: ["all", "astronomy"],
    ftpPort: "21",
    hidden: true
  },
].filter(s => !s.hidden);

interface IFilter {
  name: string;
  internalName: string;
}

const Filters = (props: {
  filters: IFilter[];
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div className={styles.filterContainer}>
      {props.filters.map((f) => (
        <button
          onClick={() => props.setSelectedFilter(f.internalName)}
          className={
            styles.filterItem +
            " " +
            (props.selectedFilter === f.internalName
              ? styles.selectedFilter
              : "")
          }
        >
          {f.name}
        </button>
      ))}
    </div>
  );
};

type MyPopupProps = {
  children:
    | React.ReactNode
    | ((close: () => void, isOpen: boolean) => React.ReactNode);
};

export function MyPopup(props: MyPopupProps & Omit<PopupProps, "children">) {
  return <Popup {...(props as PopupProps)} />;
}

export interface ISpecification {
  name: string;
  shortDescription?: string;
  sourceDescription?: string;
  contentsDescription?: string;
  usageDescription?: string;
}

export const DataStoreExplorer = () => {
  const [open, setOpen] = useState(false);
  const [specification, setSpecification] = useState<
    ISpecification | undefined
  >(undefined);
  const [dataset, setDataset] = useState<IDataset>(datasets[0]);
  const [selectedFilter, setSelectedFilter] = useState("all");

  const openDownload = (dataset: IDataset) => {
    setOpen(true);
    setDataset(dataset);
  };

  const relevantDatasets = datasets.filter((d) =>
    d.categories.includes(selectedFilter)
  );

  return (
    <div>
      <MyPopup
        modal
        nested
        onClose={() => setOpen(false)}
        open={open}
        closeOnDocumentClick
        position="top left"
      >
        {(close) => <DownloadPopup dataset={dataset} />}
      </MyPopup>
      <MyPopup
        modal
        nested
        onClose={() => setSpecification(undefined)}
        open={specification !== undefined}
        closeOnDocumentClick
        position="top left"
      >
        {(close) => (
          <SpecificationPopup
            specification={specification}
            edit={false}
            close={close}
            walletAddress=""
            refresh={() => {}}
          />
        )}
      </MyPopup>
      <DataStoreTitle />
      <Filters
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        filters={[
          { name: "All", internalName: "all" },
          { name: "Astronomy", internalName: "astronomy" },
          { name: "Climate Data", internalName: "climate" },
          { name: "Medicine", internalName: "medicine" },
          { name: "ML Training Data", internalName: "machine-learning" },
          { name: "Cybersecurity", internalName: "cyber-security" },
          { name: "Biology", internalName: "biology" },
          { name: "Transportation", internalName: "transportation" },
          // { name: "Under 100 GB", internalName: "small" }
          // { name: "Under 100 GB", internalName: "small" }
        ]}
      />
      <div className={styles.sectionTitle}>
        {relevantDatasets.length > 0
          ? "Matching Datasets"
          : "No Matching Datasets Found"}
      </div>
      <div className={styles.datasetContainer}>
        {relevantDatasets.map((d) => (
          <Dataset
            dataset={d}
            openSpecification={() => setSpecification(d.specification)}
            openModal={(d) => openDownload(d)}
          />
        ))}
      </div>
    </div>
  );
};
