import './App.css';
import { NavBarDesktop, NavBarMobile, TopNavMobile } from './components/NavBar';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { DataStore } from './components/DataStore/DataStore';
import { DataStoreExplorer } from './components/DataStoreExplorer/DataStoreExplorer';
import { StarChainAI } from './components/StarChainAI/StarChainAI';
import { PeerReview } from './components/PeerReview/PeerReview';
import { Developers } from './components/Developers/Developers';
import { RewardClaim } from './components/Rewards/RewardClaim';
import { Referral } from './components/Referral/Referral';
import { GatedFunctionality } from './components/functions/GatedFunctionality';
import { MyUploads } from './components/MyUploads/MyUploads';
import style from './components/NavBar.module.css'
import { useState } from 'react';
import { Dialog, Drawer, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

export const downloadHost = "ftp://54.201.167.24"
export const uploadHost = "ftp://datastore.couchdrop.io"

const PageLayoutMobile = () => {
  return <div className="page">
    <header className="header">
      {/* <NavBarMobile /> */}
    </header>
    <div>
      <Outlet />
    </div>
  </div>
}

const PageLayoutDesktop = () => {
  return <div className="page">
    {/* <div className="left"> */}
    {/* <NavBarDesktop /> */}
    {/* </div> */}
    {/* <div className="right">  */}
    <Outlet />
    {/* </div> */}
  </div>
}

const Page = () => {
  return <div>
    <Outlet />
  </div>
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const PageLayout = (props: { element: JSX.Element }) => {
  const [navOpen, setNavOpen] = useState(false);

  return <div className="page">
    
    <Drawer open={navOpen} onClose={() => setNavOpen(false)} PaperProps={{
            sx: { width: "52%" }, style: {background: "black"}}}>

      <NavBarMobile navOpen={navOpen} setNavOpen={setNavOpen}  />
</Drawer>
    <TopNavMobile navOpen={navOpen} setNavOpen={setNavOpen} />
    <div className="left">
      <NavBarDesktop />
    </div>
    <div className={style.right}>
      {props.element}
    </div>
  </div>
}

const BasicGated = (titleRegular: string, titleGradient: string, applicationUrl: string) => {
  const DataStoreTitle = () => {
    return <h1 className='page-title'>{titleRegular}<span className="gradient">{titleGradient}</span></h1>
  }
  return (
      <div>
          {DataStoreTitle()}
          <GatedFunctionality applicationUrl={applicationUrl} />
      </div>
  )
}

export const routes = [
  {
    name: "DataStore Explorer",
    path: "/",
    element: <PageLayout element={<DataStoreExplorer />} />,
    hidden: false,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/Frame%201707480491.svg?alt=media&token=ef6642bd-0393-4fff-8b2d-8c522253ea75"
  },
  {
    name: "My DataStore",
    path: "/my-datastore",
    element: <PageLayout element={<DataStore />} />,
    hidden: false,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/Frame%201707480493.svg?alt=media&token=035236a6-ed7e-4d5c-802f-728816c70ce9",
    comingSoon: true
  },
  // {
  //   name: "My DataStore",
  //   path: "/datastore",
  //   element: <DataStore />,
  //   hidden: true,
  // },
  {
    name: "Publish Dataset",
    path: "/publish",
    element: <PageLayout element={<MyUploads />} />,
    hidden: false,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/Frame%201707480492.svg?alt=media&token=b8d654be-9e85-4038-8ec9-627b8e8a4cfb"
  },
  {
    name: "StarChain AI",
    path: "/starchain-ai",
    element: <PageLayout element={BasicGated("StarChain ", "AI", "https://docs.google.com/forms/d/e/1FAIpQLSdcusciP0af9QsfK0cAkFQJr3zFP9qxGRvgKvJ6oySCdlAOQw/viewform")} />,
    hidden: false,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/Frame%201707480494.svg?alt=media&token=328d48ed-4063-4249-8033-87961c97ea77",
  },
  {
    name: "Peer Review",
    path: "/peer-review",
    element: <PageLayout element={BasicGated("Peer ", "Review", "https://docs.google.com/forms/d/1PNww__TOI7kyiYnjtFPcZimXjuNoMNk34AEvFstuXfg/viewform")} />,
    hidden: false,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/Frame%201707480497.svg?alt=media&token=57bdaa29-e0d8-446d-86df-c92ca8c73fbc"
  },
  {
    name: "My Rewards",
    path: "/rewards",
    element: <PageLayout element={<RewardClaim />} />,
    hidden: false,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/Frame%201707480495.svg?alt=media&token=2a0e1284-c518-4a53-8412-2d9a1ba6d37a",
    comingSoon: true
  },
  {
    name: "Referral",
    path: "/referral",
    element: <Referral />,
    hidden: true,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/referrals-line-icon-vector%20(1).svg?alt=media&token=8bd71147-dba2-4402-8c2f-26975e910026",
    bigImage: true
  },
  {
    name: "For Developers",
    path: "/developers",
    element: <PageLayout element={BasicGated("DevTools", "", "mailto:jennifer.bircher@starchain.dev")} />,
    hidden: false,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/Frame%201707480504.svg?alt=media&token=eca2fe14-9a1c-4073-a626-e86ee40f0700",
  }
]

const router = createBrowserRouter([
  {
    element: <Page />,
    children: routes,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;